
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Filter } from '@/types/ListTypes';
import { isNaN } from 'lodash';

interface RangeFilter {
  start?: string|number|undefined|null,
  end?: string|number|undefined|null,
  'include_null'?: boolean
}

@Component({
  name: 'RangeFilterDisplayValues',
})
export default class RangeFilterDisplayValues extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  @Prop({ default: (data: string|number) => (data).toString() })
  private readonly converter!: (data: string|number) => string;

  protected getAppliedValuesArray(): Array<RangeFilter> {
    const { data } = this.filter;
    const appliedValuesArray: Array<RangeFilter> = [];

    if ((data as RangeFilter).start || (data as RangeFilter).end) {
      appliedValuesArray.push({
        start: (data as RangeFilter).start,
        end: (data as RangeFilter).end,
      });
    }

    if ((data as RangeFilter).include_null) {
      appliedValuesArray.push({
        include_null: (data as RangeFilter).include_null,
      });
    }

    return appliedValuesArray;
  }

  protected getDisplayValue(value: RangeFilter): string {
    if (
      (typeof value.start === 'number' || typeof value.start === 'string')
      && (typeof value.end === 'number' || typeof value.end === 'string')
    ) {
      return `${this.converter(value.start)} ${this.filter.metadata?.labels?.up_to_and_including} ${this.converter(value.end)}`;
    }
    if ((typeof value.start === 'number' || typeof value.start === 'string') && value.end === null) {
      return `≥${this.converter(value.start)}`;
    }
    if (value.start === null && (typeof value.end === 'number' || typeof value.end === 'string')) {
      return `≤${this.converter(value.end)}`;
    }
    if (value.include_null) {
      return `<i>${this.filter.metadata?.labels?.empty}</i>`;
    }

    throw Error(`Given value ('${value}') is not of the type range. No range properties found`);
  }

  protected removeFilter(value: RangeFilter): void {
    Object.keys(value).forEach((key: string) => {
      (this.filter.data as Record<string, unknown>)[key] = null;
    });
    const amountOfSetValues = Object.values(this.filter.data as Record<string, unknown>)
      .filter((propertyValue) => propertyValue !== null && !isNaN(propertyValue));

    this.filter.applied = amountOfSetValues.length > 0;
    this.$emit('filter-removed');
  }
}
